<template>
  <section id="card-preview" class="media-preview">
    <div v-if="isSupportedBtnPlatform(msg)" class="slider">
      <div v-bind:style="{ height: getHeight() }" class="slide_viewer">
        <div
          class="slide"
          v-for="(card, index) in getCards"
          :key="index"
          v-bind:style="{
            transform: getTransform(position),
            opacity: getOpacity(index)
          }"
        >
          <vs-card class="cardx">
            <div v-if="card.header && card.header.url" slot="media">
              <img :src="card.header.url" />
            </div>
            <div class="title">{{ card.title }}</div>
            <div class="subtitle">{{ card.subtitle }}</div>
            <div class="buttons">
              <div
                v-for="(button, index) in card.options"
                v-if="button.text"
                :key="index"
                class="option"
              >
                <span>{{ button.text }}</span>
              </div>
            </div>
          </vs-card>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="name">{{ msg.type.name }}</div>
    </div>
  </section>
</template>

<script>
import { FILE_FORMATS_MAP } from '@/constants/constants'
import { MESSAGE_TYPES_CHANNELS } from '../constants/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'CardPreview',
  props: {
    msg: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      position: 0,
      cardWith: 147,
      showPreviewVideo: false,
      FILE_FORMATS_MAP,
      output: ''
    }
  },
  computed: {
    ...mapGetters('botMaker', ['activeChannelView', 'sliderChange']),
    mediaType() {
      return (
        (this.file && this.file.type.split('/')[0]) ||
        (this.savedFile && this.FILE_FORMATS_MAP[this.savedFile.fileFormat])
      )
    },
    getCards() {
      return this.msg.type.responseCard
    },
    cardSize() {
      return this.msg.type.responseCard.length
    }
  },
  methods: {
    back() {
      //alert('BACK')
    },
    forward() {
      //alert('FORWARD')
    },
    isSupportedBtnPlatform(msg) {
      const result = MESSAGE_TYPES_CHANNELS[msg.type.getType()].includes(
        this.activeChannelView
      )
      return result
    },
    getOptions() {
      const card = this.getCard()
      return card.options
    },
    getHeight() {
      return 'fit-content'
    },
    getTransform(index) {
      return 'translateX(-' + this.cardWith * index + 'px)'
    },
    getOpacity(index) {
      if (index == this.position) return 1
      else return 0
    },
    getCard(index) {
      return this.msg.type.responseCard[index]
    },
    getTitle(index) {
      const card = this.getCard(index)
      return card.title
    },
    getSubtitle(index) {
      const card = this.getCard(index)
      return card.subtitle
    },
    getImage(index) {
      const card = this.getCard(index)
      return card.header.url
    },
    lastToFirst(array) {
      return array.unshift(array.pop())
    }
  },
  watch: {
    file() {
      this.preview(this.file)
    },
    savedFile() {
      this.preview()
    },
    sliderChange(newVal) {
      if (newVal.msg_id == this.msg.id) {
        if (newVal.forward) {
          if (this.position + 1 < this.cardSize) {
            this.position++
          } else {
            this.position = 0
          }
        } else {
          if (this.position == 0) {
            this.position = this.cardSize - 1
          } else {
            this.position--
          }
        }
      }
    }
  },
  mounted() {
    // setInterval(() => {
    //   if (this.position + 1 < this.cardSize) {
    //     this.position++
    //   } else {
    //     this.position = 0
    //   }
    // }, 4000)
  }
}
</script>

<style lang="scss">
#card-preview {
  width: 100%;
  position: relative;
  overflow: visible;
  /* Slider */

  .slider {
    width: 100%;
    position: relative;
    margin: 0 auto;
  }
  .slide_viewer {
    width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;
  }
  .slide_group {
    height: 100%;
    position: relative;
    width: 100%;
  }
  .slide {
    transition: 0.5s ease-in-out;
    height: 100%;
    width: 250px;
    min-width: 100%;
    display: block;
  }

  .con-vs-card {
    background-color: #f0f0f0;
  }
  img {
    height: auto;
    width: 100%;
  }
  .title {
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
  }
  .subtitle {
    font-size: 1rem;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 0 0 20px 20px;

    .option {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 5px;
      line-height: 1rem;
      background-color: white;
      color: black;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
